import { Box, SelectProps as ChakraSelectProps } from '@chakra-ui/react';
import { ChakraProps } from '@chakra-ui/system';
import React from 'react';
import { IconArrow } from './BrandIcons';

interface HeaderProps extends ChakraProps {
  size: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  children: React.ReactNode;
}

export const Header = ({ size, children, ...rest }: HeaderProps) => (
  <Box as={size} textStyle={size} {...rest}>
    {children}
  </Box>
);

interface TextProps extends ChakraProps {
  size: 'p1' | 'p2' | 'p3' | 'p4' | 'p5' | 'p6';
  children: React.ReactNode;
}

export const Text = ({ size, children, ...rest }: TextProps) => {
  return (
    <Box as="p" textStyle={size} {...rest}>
      {children}
    </Box>
  );
};

// interface ButtonProps extends ChakraButtonProps {
//   children: React.ReactNode;
// }
//
// export const Button = ({ children, ...rest }: ButtonProps) => (
//   <ChakraButton
//     bg="orange.1"
//     color="white.1"
//     borderRadius="base"
//     fontSize="p3"
//     fontWeight="bold"
//     boxShadow="0px 0px 10px rgba(0, 0, 0, 0.25)"
//     // textShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
//     p={2}
//     _hover={{
//       background: 'orange.1',
//       color: 'white.1',
//     }}
//     _active={{
//       background: 'orange.1',
//       color: 'white.1',
//     }}
//     _focusVisible={{
//       boxShadow: '0 0 0 3px rgba(64, 22, 5, 1)',
//     }}
//     {...rest}
//   >
//     {children}
//   </ChakraButton>
// );

interface SelectOptions {
  value: string | number;
  text: string;
}

interface SelectProps extends ChakraSelectProps {
  options: SelectOptions[];
  onChange: (e: any) => void;
  value?: any;
  isDisabled?: boolean;
}

export const Select = ({
  options,
  onChange,
  value,
  isDisabled,
}: SelectProps) => {
  console.log('options', options);
  return (
    <select
      value={value}
      disabled={isDisabled}
      defaultValue={value}
      onChange={onChange}
      placeholder="Choose Your Campus"
      className=" md:max-w-none w-full md:w-[624px] h-[40px] relative px-[15px] text-[#331103] text-sm md:text-lg font-medium leading-[30px] bg-white rounded-xl border border-[#d0d5dd] justify-start items-center gap-2.5 flex outline-none hover:outline-none"
    >
      {options.map(({ value, text }) => (
        <option
          key={value}
          value={value}
          style={{ backgroundColor: '#FFF8F5' }}
        >
          {text}
        </option>
      ))}
    </select>
  );
};
