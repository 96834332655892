import Logo from '../Common/Logo';
import React, { useContext, useEffect, useState } from 'react';
import {
  ShoppingCartIcon,
  Bars3CenterLeftIcon,
} from '@heroicons/react/24/outline';
import BuildingSelect from 'components/Common/BuildingSelect';
import { useDBContext } from 'context/dbContext';
import { LocationDispatchContext, useLocation } from 'context/LocationContext';
import Sidebar from './Sidebar';
import Cookies from 'js-cookie';
import { useOrder } from 'context/OrderContext';
import { useRouter } from 'next/router';
import PartnerSidebar from 'components/partner/Sidebar';
import BuildingResidentSelect from 'components/Common/BuildingResidentSelect';

interface HeaderProps {
  className?: string; // className is optional
}

const Header = (prop: HeaderProps) => {
  const router = useRouter();

  const { locations, userProfile } = useDBContext();
  const setLocations = useContext(LocationDispatchContext);
  const [value, setValue] = useState<any>();
  const locationContext: any = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const dataOrder = useOrder();
  const isLoginPage = router.pathname === '/login';

  useEffect(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    const location: any = Cookies.get('location');

    if (location) {
      setValue(JSON.parse(location));
    }
  }, [location, locationContext]);
  console.log('userProfile', userProfile);
  return (
    <>
      <>
        <div className="justify-center items-center flex w-full fixed left-0 top-0 h-auto md:h-[70px] z-[999] bg-[#f8f8f8] md:bg-[#331104]">
          <div className="w-full px-0 md:px-10 pb-3 md:py-4 justify-start md:justify-between items-start md:items-center flex md:flex-row flex-col">
            <div className="w-full md:px-0 px-5 md:py-0 py-5 md:border-none border-b border-[#eaecf0] flex justify-between items-center gap-2">
              <Logo />

              {/* <button className="max-w-[129px] w-full h-10 px-2 md:hidden bg-[#f75413] rounded-[5px] shadow justify-center items-center flex">
                <p className="text-center text-[#fff7f4] text-base font-semibold leading-normal">
                  Open app
                </p>
              </button> */}
            </div>

            <div className="justify-between md:w-auto w-full items-center gap-3 flex md:px-0 px-5 md:pt-0 pt-3">
              {/* <Bars3CenterLeftIcon
                className="w-7 h-7"
                onClick={() => setIsOpen(!isOpen)}
              /> */}
              {/* {!isLoginPage && userProfile && (
                <div className="flex w-full md:w-auto justify-end items-center gap-5">
                  <BuildingResidentSelect
                    value={value}
                    regionId={userProfile?.location?.regionId}
                    locationId={userProfile?.location?.id}
                    locations={locations}
                    onChange={(location) => {
                      Cookies.set('location', JSON.stringify(location), {
                        expires: 365,
                      });
                      setLocations(location);
                    }}
                  />
                  <div
                    className="relative w-[52px] md:px-[15px] h-[50px] md:py-2.5 bg-transparent md:bg-white rounded-xl flex-col justify-center items-center gap-2.5 flex"
                    onClick={() => {
                      router.push('/deal-orgs/order-summary?type=deal');
                    }}
                  >
                    <div className="w-[25px] h-auto flex items-center justify-center">
                      <ShoppingCartIcon />
                    </div>
                    <div className="absolute bg-[#e83e0e] rounded-full right-[7px] top-[8px] justify-center items-center flex text-white pb-0 pt-px px-1 text-[9px] font-bold">
                      {dataOrder?.orders ? 1 : 0}
                    </div>
                  </div>
                </div>
              )} */}
            </div>
          </div>
        </div>

        {/* Mobile Menu */}
        {isOpen && router?.asPath?.includes('partner') ? (
          <PartnerSidebar isOpen={isOpen} setIsOpen={setIsOpen} />
        ) : isOpen ? (
          <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />
        ) : (
          <></>
        )}
      </>
    </>
  );
};

export default Header;
