import React, { useState } from 'react';
import PartnerLayout from 'components/layout/PartnerLayout';
import { useSessionContext, useUser } from '@supabase/auth-helpers-react';

import { XMarkIcon } from '@heroicons/react/24/outline';

const ResetPasswordPopup = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { isLoading: userLoading, supabaseClient } = useSessionContext();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const validatePassword = (password: string) => {
    const minLength = /.{8,}/; // At least 8 characters
    const hasLowercase = /[a-z]/; // At least 1 lowercase letter
    const hasUppercase = /[A-Z]/; // At least 1 uppercase letter
    const hasNumber = /[0-9]/; // At least 1 number
    const hasSpecialChar = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/; // At least 1 special character

    if (!minLength.test(password)) {
      return 'Password must be at least 8 characters long.';
    }
    if (!hasLowercase.test(password)) {
      return 'Password must contain at least one lowercase letter.';
    }
    if (!hasUppercase.test(password)) {
      return 'Password must contain at least one uppercase letter.';
    }
    if (!hasNumber.test(password)) {
      return 'Password must contain at least one number.';
    }
    if (!hasSpecialChar.test(password)) {
      return 'Password must contain at least one special character.';
    }
    return null;
  };

  const handleUpdatePassword = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (!password || !confirmPassword) {
      setError('Please fill in both fields.');
      return;
    }

    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    const passwordError = validatePassword(password);
    if (passwordError) {
      setError(passwordError);
      return;
    }

    try {
      const { error } = await supabaseClient.auth.updateUser({ password });

      if (error) {
        setError(error.message);
      } else {
        setSuccess('Password updated successfully!');
        onClose();
      }
    } catch (err) {
      setError('Something went wrong. Please try again.');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed w-full inset-0 flex items-center justify-center bg-black bg-opacity-50 !z-[1000000]">
      <div className="bg-white p-6 rounded-lg shadow-md w-[calc(45%-8px)] relative">
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          onClick={onClose}
        >
          <XMarkIcon className="w-5 h-5" />
        </button>
        <h2 className="text-xl font-bold mb-4 text-center">Reset Password</h2>
        <form onSubmit={handleUpdatePassword}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600 mb-3">
              New Password
            </label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-300"
              placeholder="Enter new password"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600 mb-3">
              Confirm Password
            </label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-300"
              placeholder="Confirm new password"
            />
          </div>
          {error && (
            <p className="text-red-500 text-sm mb-2 text-center">{error}</p>
          )}
          {success && (
            <p className="text-green-500 text-sm mb-2 text-center">{success}</p>
          )}
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 mb-4 rounded-md hover:bg-blue-600 transition duration-200"
          >
            Update Password
          </button>
        </form>

        <div className="text-xs text-gray-600 mb-3">
          <p className="font-semibold my-3">Password must contain:</p>
          <ul className="list-disc ml-4">
            <li>Minimum 8 characters</li>
            <li>At least 1 uppercase letter</li>
            <li>At least 1 lowercase letter</li>
            <li>At least 1 number</li>
            <li>
              At least 1 special character (!@#$%^&amp;*()_+[]{}
              |;:&apos;&quot;,.&lt;=&gt;?/`~)
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

// Example usage of the popup

export default ResetPasswordPopup;
