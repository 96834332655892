import { Flex, Button, Spacer, Box } from '@chakra-ui/react';
import TopMenu from './TopMenu';
import { Action, ActionHandler } from '../misc/action-types';
// import Footer from './Footer';
import { ReactNode, useContext, useEffect } from 'react';
import { UserProfileClientAPI } from '../../db/types-client-api';
import Footer from 'components/landing-page/footer';
import Header from 'components/deals/Header';
import Cookies from 'js-cookie';
import { OrderDispatchContext } from 'context/OrderContext';

interface Props {
  loadingUserProfile: boolean;
  userProfile: UserProfileClientAPI | null;
  onAction: ActionHandler;
  children: ReactNode | ReactNode[];
}

const AppLayout = ({ children }: Props) => {
  const setUserDetails = useContext(OrderDispatchContext);

  useEffect(() => {
    const order = localStorage.getItem('orders');
    const menu = localStorage.getItem('menu');

    if (order && menu) {
      setUserDetails({ orders: JSON.parse(order), menu: JSON.parse(menu) });
    }
  }, []);

  return (
    <Box maxW="100w" overflowX="hidden">
      {location.pathname !== '/' && (
        <Header />
        // <TopMenu
        //   loadingUserProfile={loadingUserProfile}
        //   onSignUp={() => onAction(Action.SignUp)}
        //   onLogout={() => onAction(Action.Logout)}
        //   userProfile={userProfile}
        // />
      )}
      {children}
      {location.pathname !== '/' &&
      location.pathname !== '/partner' &&
      location.pathname !== '/partner/resident' &&
      location.pathname !== '/partner/laundry' &&
      location.pathname !== '/partner/resident/edit' &&
      location.pathname !== '/partner/history' ? (
        <Footer></Footer>
      ) : null}
    </Box>
  );
};

export default AppLayout;
