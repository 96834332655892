import Link from 'next/link';
import React, { useState } from 'react';
import {
  BookOpenIcon,
  LockClosedIcon,
  UserCircleIcon,
  ArrowPathIcon,
} from '@heroicons/react/24/outline';
import { useRouter } from 'next/router';
import classNames from 'utils/classNames';
import { useSessionContext, useUser } from '@supabase/auth-helpers-react';
import ResetPasswordPopup from './ResetPasswordPopup';

const PartnerSidebar = ({ isOpen, setIsOpen }: any) => {
  const { supabaseClient } = useSessionContext();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const router = useRouter();
  const topData = [
    {
      label: 'Meal Vouchers',
      url: '/partner',
    },
    {
      label: 'Laundry Vouchers',
      url: '/partner/laundry',
    },
    {
      label: 'Resident List',
      url: '/partner/resident',
      // icon: <QuestionMarkCircleIcon className="w-6 h-6" />,
    },
    {
      label: 'Order History',
      url: '/partner/history',
      // icon: <Cog8ToothIcon className="w-6 h-6" />,
    },

    // {
    //   label: 'Referrals',
    //   url: '/referrals',
    //   icon: (
    //     <svg
    //       className="w-5 h-5 transition duration-75"
    //       width="18"
    //       height="20"
    //       viewBox="0 0 18 20"
    //       fill="currentColor"
    //       xmlns="http://www.w3.org/2000/svg"
    //     >
    //       <path
    //         fillRule="evenodd"
    //         clipRule="evenodd"
    //         d="M12.5479 0.149241C12.0668 -0.136313 11.4315 0.00048038 11.1291 0.454778L9.00025 3.65269L6.87143 0.454684C6.56901 0.000379469 5.9338 -0.136427 5.45266 0.149119C4.97151 0.434664 4.82662 1.03443 5.12903 1.48873L6.78492 3.97628H2C0.89543 3.97628 0 4.87304 0 5.97924V7.98221C0 8.72359 0.402199 9.37089 1 9.71721V9.98517V16.9956C1 18.6549 2.34314 20 4 20H14C15.6569 20 17 18.6549 17 16.9956V9.98517V9.71721C17.5978 9.37089 18 8.72359 18 7.98221V5.97924C18 4.87304 17.1046 3.97628 16 3.97628H11.2156L12.8715 1.48886C13.1739 1.03457 13.029 0.434796 12.5479 0.149241ZM2 7.98221H3H4H8V5.97924H4H2V7.98221ZM10 5.97924V7.98221H14H15H16V5.97924H14H10ZM8 9.98517H5H3V11.9881V16.9956C3 17.5487 3.44772 17.997 4 17.997H8V9.98517ZM10 17.997V9.98517H13H15V11.9881V16.9956C15 17.5487 14.5523 17.997 14 17.997H10Z"
    //       />
    //     </svg>
    //   ),
    // },
    // {
    //   label: 'Orders',
    //   url: '/orders',
    //   icon: (
    //     <svg
    //       className="w-5 h-5 transition duration-75"
    //       width="20"
    //       height="20"
    //       viewBox="0 0 20 20"
    //       fill="currentColor"
    //       xmlns="http://www.w3.org/2000/svg"
    //     >
    //       <path
    //         fillRule="evenodd"
    //         clipRule="evenodd"
    //         d="M10.7068 0.556424C9.76204 -0.185482 8.41203 -0.185482 7.46721 0.556424L1.91874 4.97383C1.33982 5.43443 1 6.13312 1 6.87118V12.9985C1 13.7366 1.33982 14.4353 1.91874 14.8959L7.46721 19.3133C8.41203 20.0552 9.76204 20.0552 10.7068 19.3133L16.2553 14.8959C16.8342 14.4353 17.174 13.7366 17.174 12.9985V6.87118C17.174 6.13312 16.8342 5.43443 16.2553 4.97383L10.7068 0.556424ZM7.46721 2.12706C7.7914 1.87835 8.38266 1.87835 8.70685 2.12706L14.2553 6.54446C14.5795 6.79317 14.7436 7.14694 14.7436 7.52118C14.7436 7.89542 14.5795 8.24919 14.2553 8.4979L8.70685 12.9153C8.38266 13.164 7.7914 13.164 7.46721 12.9153L1.91874 8.4979C1.59456 8.24919 1.4305 7.89542 1.4305 7.52118C1.4305 7.14694 1.59456 6.79317 1.91874 6.54446L7.46721 2.12706ZM4.60834 8.00916L8.75368 11.0881L12.8984 8.00916L8.75368 4.93021L4.60834 8.00916Z"
    //       />
    //     </svg>
    //   ),
    // },
  ];

  const botData = [
    {
      label: 'Terms & Conditions',
      url: '/terms',
      icon: <BookOpenIcon className="w-6" />,
    },
    {
      label: 'Privacy Policy',
      url: '/privacy',
      icon: <LockClosedIcon className="w-6" />,
    },
  ];
  return (
    <>
      <ResetPasswordPopup
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      <aside
        id="separator-sidebar"
        className={classNames(
          'fixed top-0 left-0 pt-[150px] md:pt-[70px] !z-[99] w-72 h-screen transition-transform -translate-x-full md:!translate-x-0',
          isOpen
            ? '!translate-x-0 !w-full md:opacity-0 opacity-100'
            : '!-translate-x-full opacity-0 !z-[-1] md:opacity-100'
        )}
        aria-label="Sidebar"
      >
        <div className="flex flex-col justify-between items-start gap-8 h-full px-6 py-8 overflow-hidden bg-white no-scrollbar">
          <ul className="flex flex-col gap-6 w-full h-auto font-medium">
            {topData?.map((d, idx) => (
              <li
                key={idx}
                className={classNames(
                  'px-3 py-2 rounded-lg hover:text-[#F75413] text-[#331104] hover:bg-[#FDEAD7] hover:font-semiBold',
                  router?.asPath === d.url ? 'bg-[#FDEAD7] text-[#F75413]' : ''
                )}
                onClick={() => setIsOpen && setIsOpen(false)}
              >
                <Link href={d.url}>
                  <a className="w-ful flex items-center gap-3 group">
                    {/* {d.icon} */}
                    {d.label}
                  </a>
                </Link>
              </li>
            ))}
          </ul>

          <div className="h-auto w-full flex-col justify-start items-start gap-6 flex">
            <ul className="flex flex-col gap-2 w-full h-auto font-medium">
              <li className="px-3 py-2 text-[#331104]">
                <button
                  className="w-ful flex items-center gap-3 group"
                  onClick={() => setIsModalOpen(true)}
                >
                  <ArrowPathIcon className="w-6" />
                  Reset Password
                </button>
              </li>
              <li className="px-3 py-2 text-[#331104]">
                <button
                  className="w-ful flex items-center gap-3 group"
                  onClick={async () => {
                    await supabaseClient.auth.signOut();
                    window.location.href = '/';
                  }}
                >
                  <UserCircleIcon className="w-6" />
                  Logout
                </button>
              </li>
            </ul>
          </div>
        </div>
      </aside>
    </>
  );
};

export default PartnerSidebar;
