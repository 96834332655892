import Link from 'next/link';
import React from 'react';
import {
  Cog8ToothIcon,
  QuestionMarkCircleIcon,
  ArrowRightStartOnRectangleIcon,
  BookOpenIcon,
  LockClosedIcon,
} from '@heroicons/react/24/outline';
import { useRouter } from 'next/router';
import classNames from 'utils/classNames';

const Sidebar = ({ isOpen, setIsOpen }: any) => {
  const router = useRouter();
  const topData = [
    {
      label: 'Deals',
      url: '/deal-orgs',
      icon: (
        <svg
          className="w-5 h-5 transition duration-75"
          width="14"
          height="20"
          viewBox="0 0 14 20"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.28213 0.312942C9.58181 0.62984 9.64153 1.10441 9.42971 1.48568C9.41535 1.51153 9.40104 1.53723 9.38681 1.56277C9.09587 2.08504 8.8384 2.54725 8.84711 3.1256C8.85525 3.66548 9.24403 4.64752 10.5338 5.73574C12.2794 7.20864 13.1423 9.02444 13.569 10.4472C13.7828 11.1602 13.8901 11.784 13.9442 12.2342C13.9714 12.4597 13.9853 12.643 13.9924 12.7738C13.996 12.8392 13.9979 12.8916 13.9989 12.9297C13.9994 12.9488 13.9997 12.9643 13.9998 12.976L14 12.9909L14 12.9962L14 12.9983C14 12.9983 14 13 13 13H14C14 16.866 10.866 20 7 20C3.13401 20 0 16.866 0 13C0 11.0708 0.270818 9.38719 0.655314 8.13731C0.846426 7.51606 1.07537 6.97001 1.33429 6.54645C1.46359 6.33495 1.61569 6.1286 1.79493 5.95593C1.96706 5.79011 2.22146 5.60021 2.55827 5.52443C2.83311 5.46259 3.12129 5.51954 3.35194 5.6813C3.5826 5.84305 3.73432 6.09459 3.76981 6.37406C3.80188 6.62658 3.90678 6.99636 4.07376 7.38677C4.06542 6.72353 4.11444 6.0194 4.25001 5.31185C4.62771 3.34067 5.6991 1.27308 8.11945 0.100139C8.51195 -0.09007 8.98245 -0.00395477 9.28213 0.312942ZM12 13.0027C11.9986 15.7629 9.76054 18 7 18C4.23858 18 2 15.7615 2 13C2 11.2915 2.23503 9.84006 2.54224 8.80693C2.62101 8.95127 2.70476 9.09281 2.79301 9.22961C3.29556 10.0087 4.18578 11 5.44445 11C5.75953 11 6.05618 10.8515 6.245 10.5993C6.43382 10.3471 6.49271 10.0206 6.40394 9.71828C6.14438 8.83438 5.91532 7.24848 6.21428 5.68822C6.34182 5.02257 6.56129 4.37519 6.90932 3.7881C7.11181 4.90179 7.82121 6.06382 9.24403 7.26433C10.6095 8.41642 11.3021 9.85063 11.6533 11.0216C11.8283 11.6055 11.9154 12.1145 11.9585 12.4729C11.98 12.6517 11.9904 12.7916 11.9954 12.883C11.9979 12.9287 11.9991 12.9621 11.9996 12.982L12 13.0016L12 13.0027Z"
          />
        </svg>
      ),
    },
    {
      label: 'Support',
      url: '/faq',
      icon: <QuestionMarkCircleIcon className="w-6 h-6" />,
    },
    {
      label: 'Settings',
      url: '/settings',
      icon: <Cog8ToothIcon className="w-6 h-6" />,
    },
    // {
    //   label: 'Referrals',
    //   url: '/referrals',
    //   icon: (
    //     <svg
    //       className="w-5 h-5 transition duration-75"
    //       width="18"
    //       height="20"
    //       viewBox="0 0 18 20"
    //       fill="currentColor"
    //       xmlns="http://www.w3.org/2000/svg"
    //     >
    //       <path
    //         fillRule="evenodd"
    //         clipRule="evenodd"
    //         d="M12.5479 0.149241C12.0668 -0.136313 11.4315 0.00048038 11.1291 0.454778L9.00025 3.65269L6.87143 0.454684C6.56901 0.000379469 5.9338 -0.136427 5.45266 0.149119C4.97151 0.434664 4.82662 1.03443 5.12903 1.48873L6.78492 3.97628H2C0.89543 3.97628 0 4.87304 0 5.97924V7.98221C0 8.72359 0.402199 9.37089 1 9.71721V9.98517V16.9956C1 18.6549 2.34314 20 4 20H14C15.6569 20 17 18.6549 17 16.9956V9.98517V9.71721C17.5978 9.37089 18 8.72359 18 7.98221V5.97924C18 4.87304 17.1046 3.97628 16 3.97628H11.2156L12.8715 1.48886C13.1739 1.03457 13.029 0.434796 12.5479 0.149241ZM2 7.98221H3H4H8V5.97924H4H2V7.98221ZM10 5.97924V7.98221H14H15H16V5.97924H14H10ZM8 9.98517H5H3V11.9881V16.9956C3 17.5487 3.44772 17.997 4 17.997H8V9.98517ZM10 17.997V9.98517H13H15V11.9881V16.9956C15 17.5487 14.5523 17.997 14 17.997H10Z"
    //       />
    //     </svg>
    //   ),
    // },
    // {
    //   label: 'Orders',
    //   url: '/orders',
    //   icon: (
    //     <svg
    //       className="w-5 h-5 transition duration-75"
    //       width="20"
    //       height="20"
    //       viewBox="0 0 20 20"
    //       fill="currentColor"
    //       xmlns="http://www.w3.org/2000/svg"
    //     >
    //       <path
    //         fillRule="evenodd"
    //         clipRule="evenodd"
    //         d="M10.7068 0.556424C9.76204 -0.185482 8.41203 -0.185482 7.46721 0.556424L1.91874 4.97383C1.33982 5.43443 1 6.13312 1 6.87118V12.9985C1 13.7366 1.33982 14.4353 1.91874 14.8959L7.46721 19.3133C8.41203 20.0552 9.76204 20.0552 10.7068 19.3133L16.2553 14.8959C16.8342 14.4353 17.174 13.7366 17.174 12.9985V6.87118C17.174 6.13312 16.8342 5.43443 16.2553 4.97383L10.7068 0.556424ZM7.46721 2.12706C7.7914 1.87835 8.38266 1.87835 8.70685 2.12706L14.2553 6.54446C14.5795 6.79317 14.7436 7.14694 14.7436 7.52118C14.7436 7.89542 14.5795 8.24919 14.2553 8.4979L8.70685 12.9153C8.38266 13.164 7.7914 13.164 7.46721 12.9153L1.91874 8.4979C1.59456 8.24919 1.4305 7.89542 1.4305 7.52118C1.4305 7.14694 1.59456 6.79317 1.91874 6.54446L7.46721 2.12706ZM4.60834 8.00916L8.75368 11.0881L12.8984 8.00916L8.75368 4.93021L4.60834 8.00916Z"
    //       />
    //     </svg>
    //   ),
    // },
  ];

  const botData = [
    {
      label: 'Terms & Conditions',
      url: '/terms',
      icon: <BookOpenIcon className="w-6" />,
    },
    {
      label: 'Privacy Policy',
      url: '/privacy',
      icon: <LockClosedIcon className="w-6" />,
    },
  ];

  return (
    <aside
      id="separator-sidebar"
      className={classNames(
        'fixed top-0 left-0 pt-[150px] md:pt-[84px] !z-[99] w-72 h-screen transition-transform -translate-x-full md:!translate-x-0',
        isOpen
          ? '!translate-x-0 !w-full md:opacity-0 opacity-100'
          : '!-translate-x-full opacity-0 !z-[-1] md:opacity-100'
      )}
      aria-label="Sidebar"
    >
      <div className="flex flex-col justify-between items-start gap-8 h-full px-6 py-8 overflow-hidden bg-white no-scrollbar">
        <ul className="flex flex-col gap-6 w-full h-auto font-medium">
          {topData?.map((d, idx) => (
            <li
              key={idx}
              className={classNames(
                'px-3 py-2 rounded-lg hover:text-[#F75413] text-[#331104] hover:bg-[#FDEAD7] hover:font-semiBold',
                router?.asPath?.includes(d.url)
                  ? 'bg-[#FDEAD7] text-[#F75413]'
                  : ''
              )}
              onClick={() => setIsOpen && setIsOpen(false)}
            >
              <Link href={d.url}>
                <a className="w-ful flex items-center gap-3 group">
                  {d.icon}
                  {d.label}
                </a>
              </Link>
            </li>
          ))}
        </ul>

        <div className="h-auto w-full flex-col justify-start items-start gap-6 flex">
          <ul className="flex flex-col gap-2 w-full h-auto font-medium">
            {botData?.map((d, idx) => (
              <li key={idx} className="px-3 py-2 text-[#331104]">
                <Link href={d.url}>
                  <a className="w-ful flex items-center gap-3 group">
                    {d.icon}
                    {d.label}
                  </a>
                </Link>
              </li>
            ))}
          </ul>

          {/* <div className="pt-6 w-full border-t border-[#eaecf0] justify-between items-start gap-4 flex">
            <div
              className="w-full justify-start items-center gap-3 flex cursor-pointer"
              onClick={() => router.push('/settings/account')}
            >
              <div className="w-10 h-10 rounded-full justify-center items-center flex">
                <div className="w-10 h-10 relative rounded-full border border-black/10" />
              </div>
              <div className="flex-col justify-start items-start flex">
                <p className="text-[#344054] text-sm font-semibold leading-tight">
                  Olivia Rhye
                </p>
                <p className="text-[#475467] text-sm font-normal leading-tight">
                  olivia@gmail.com
                </p>
              </div>
            </div>
            <ArrowRightStartOnRectangleIcon
              className="w-6 h-6 text-[#475467] cursor-pointer"
              onClick={() => router.push('/login')}
            />
          </div> */}
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
