export enum Action {
  Close = 'close',
  SignUp = 'signup',
  // SignUpTimer = 'signup-timer',
  CreateOrder = 'create-order',
  ShowError = 'show-error',

  Logout = 'logout',
  CreateFeed = 'create-feed',
  UserMoreInfo = 'user-more-info',
  UserProfile = 'user-profile',
  PhoneChangeVerification = 'phone-verification',
  EmailChangeVerification = 'email-verification',
  Chat = 'chat',
  PlaceOrder = 'place-order',
  EditOrder = 'edit-order',
  DeleteAccount = 'delete-account',
}

export enum OrderType {
  restaurant = 'restaurant',
  rideshare = 'rideshare',
  other = 'other',
  cleaningServices = 'cleaningServices',
  grocery = 'grocery',
  laundry = 'laundry',
  experiences = 'experiences',
}

export type ActionHandler = (action: Action, params?: any) => Promise<void>;

export interface ActionAnswer {
  error?: Error | null;
  errorMsg?: string;
  statusCode?: number;
  answer?: any;
}

export const getErrorMessage = (actionAnswer?: ActionAnswer): string =>
  actionAnswer?.errorMsg || actionAnswer?.error?.message || '';

export type SubmitAnswer = { error: string } | void;

// export const splitSubmitAnswerError = (answer: SubmitAnswer) => {
//   if (answer?.error) {
//     const parts = answer?.error.split('~');
//     if (parts.length > 1) {
//       return {
//         message: parts[1],
//         field: parts[0],
//       };
//     } else {
//       return {
//         message: parts[1],
//         field: undefined,
//       };
//     }
//   }
//
//   return { message: undefined, field: undefined };
// };
